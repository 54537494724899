import React from "react"
import Layout from "../components/Layout";

const Footers : React.FC = () => {
  return (
    <Layout>
        <p>This is awesome gatsby fotters page!</p>
    </Layout>
  );
}

export default Footers;